import type { BannerPositions } from '~/@types/bannerPositions';
import { resolveBannerAssignment } from './resolver/resolveAssignment';
import { useSessionStore } from '~/stores/useSessionStore';
import { useBannerStore } from '~/stores/useBannerStore';

export async function useAssignedBanners(position: BannerPositions) {
  const router = useRouter();
  const bannerStore = useBannerStore();

  const assignments = await bannerStore.getBannerAssignments(position);

  const assignedBanners = ref([]);
  function setAssignedBanner() {
    const uri = router.currentRoute.value.path?.replace('/', '') ?? '';
    assignedBanners.value = resolveBannerAssignment(assignments, position, {
      uri: uri ? uri : 'startseite',
      isLoggedIn: useSessionStore().isLoggedIn,
    });
  }

  useNuxtApp().hook('page:loading:end', () => {
    setAssignedBanner();
  });

  setAssignedBanner();
  onMounted(() => {
    setAssignedBanner();
  });

  return { assignedBanners };
}
