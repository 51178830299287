import type { BannerPositions } from '~/@types/bannerPositions';
import { resolveChannelLocation } from './channelLocationResolver/resolver';
import { resolveChannelCondition } from './channelConditionResolver/resolver';
import type { BaseBannerData } from '~/components/banner/types';
import type { BannerAssignment } from '~/server/transformers/banner/transformer/assignments/bannerAssignmentTransformer';

export interface AssignmentResolverOptions {
  uri: string;
  isLoggedIn: boolean;
}

export interface ResolvedBannerAssignment {
  bannerId: string | null;
  banner?: BaseBannerData;
}

export function resolveBannerAssignment(
  channels: BannerAssignment[],
  channel: BannerPositions,
  options?: AssignmentResolverOptions,
): ResolvedBannerAssignment[] {
  const viableChannels = channels
    .filter((cur) => {
      return (
        cur.position === channel &&
        resolveChannelLocation(cur, options) &&
        resolveChannelCondition(cur, options)
      );
    })
    .sort((a, b) => {
      return a.priority - b.priority;
    });

  //get assignment
  const regularChannels = viableChannels.filter((cur) => !cur.isFallback);
  if (regularChannels.length) {
    return regularChannels.map((cur) => {
      return {
        bannerId: cur.bannerId,
        banner: cur.banner,
      };
    });
  }

  //get fallback
  const fallbacks = viableChannels.filter((cur) => cur.isFallback);
  if (fallbacks.length) {
    return fallbacks.map((cur) => {
      return {
        bannerId: cur.bannerId,
        banner: cur.banner,
      };
    });
  }

  return [];
}
