import { conditionRuleResolvers } from './ruleResolvers';
import type { BannerAssignment } from '~/server/transformers/banner/transformer/assignments/bannerAssignmentTransformer';
import type { BaseConditionRule } from '~/server/transformers/banner/transformer/assignments/transformConditionRule';
import type { AssignmentResolverOptions } from '../resolveAssignment';

export function resolveChannelCondition(
  channel: BannerAssignment,
  options?: AssignmentResolverOptions,
) {
  return channel.conditionRule
    ? resolveConditionRule(channel.conditionRule, options)
    : true;
}

export function resolveConditionRule(
  rule: BaseConditionRule,
  options?: AssignmentResolverOptions,
): boolean | undefined {
  const resolver = conditionRuleResolvers[rule.typeHandle];
  if (resolver) {
    return resolver(rule, options);
  }
}
