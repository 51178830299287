export enum LocationRuleType {
  Global = 'global',
  Pages = 'page',
  ExcludedPages = 'excludedPage',
  LogicGroup = 'logicGroup',
}

export enum LogicOperator {
  And = 'and',
  Or = 'or',
}

export enum ConditionRuleType {
  LOGIN_STATUS = 'loginStatus',
  LOGIC_GROUP = 'logicGroup',
}
