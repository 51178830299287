<template>
  <div
    v-if="assignedBanners[0]"
    :key="assignedBanners[0]?.banner.id"
    :class="classes"
  >
    <Banner
      :key="assignedBanners[0].banner.id"
      :banner-data="assignedBanners[0].banner"
    />
  </div>
</template>
<script setup lang="ts">
import type { BannerPositions } from '~/@types/bannerPositions';
import Banner from './banner.vue';
import { useAssignedBanners } from '~/composables/banner/useAssignedBanners';

const props = defineProps({
  position: {
    type: String as PropType<BannerPositions>,
    required: true,
  },
  classes: {
    type: String,
    required: false,
    default: () => {
      return '';
    },
  },
});

const { assignedBanners } = await useAssignedBanners(props.position);
</script>
