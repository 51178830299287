import type { LogicGroupLocationRule } from '~/server/transformers/banner/transformer/assignments/locationRules/logicGroup';
import { LocationRuleType, LogicOperator } from '../../types';
import type { AssignmentResolverOptions } from '../resolveAssignment';
import { resolveLocationRule } from './resolver';
import type { PagesLocationRule } from '~/server/transformers/banner/transformer/assignments/locationRules/pages';
import type { ExcludedPagesLocationRule } from '~/server/transformers/banner/transformer/assignments/locationRules/excludedPages';

export const locationRuleResolvers: Record<
  LocationRuleType,
  (rule: any, options?: AssignmentResolverOptions) => boolean
> = {
  [LocationRuleType.LogicGroup]: resolveLogicGroup,
  [LocationRuleType.Global]: resolveGlobalrule,
  [LocationRuleType.Pages]: resolvePagesRule,
  [LocationRuleType.ExcludedPages]: resolveExcludePages,
};

function resolveLogicGroup(
  rule: LogicGroupLocationRule,
  options?: AssignmentResolverOptions,
): boolean {
  const { operator, rules } = rule;
  if (operator === LogicOperator.And) {
    return rules.every((cur) => resolveLocationRule(cur, options));
  } else {
    return rules.some((cur) => resolveLocationRule(cur, options));
  }
}
function resolveGlobalrule(): boolean {
  return true;
}
function resolvePagesRule(
  rule: PagesLocationRule,
  options?: AssignmentResolverOptions,
): boolean {
  const matches = resolveUriMatches(options.uri, rule.pageUri);
  return matches;
}
function resolveExcludePages(
  rule: ExcludedPagesLocationRule,
  options?: AssignmentResolverOptions,
): boolean {
  const matches = resolveUriMatches(options.uri, rule.pageUri);
  return !matches;
}

function resolveUriMatches(uri: string, pattern: string) {
  const patternParts = pattern.split('/');
  const uriParts = uri.split('/');
  let matches = true;
  let patternPart;
  for (let i = 0; i < uriParts.length; i++) {
    patternPart = patternParts[i];
    if (!patternPart) {
      if (patternParts[i - 1] !== '$...') matches = false;
      break;
    }
    if (patternPart === '$*' || patternPart === '$...') {
      continue;
    }
    if (patternPart !== uriParts[i]) {
      matches = false;
      break;
    }
  }

  return matches;
}
