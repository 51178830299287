import type { BannerAssignment } from '~/server/transformers/banner/transformer/assignments/bannerAssignmentTransformer';
import type { AssignmentResolverOptions } from '../resolveAssignment';
import { locationRuleResolvers } from './ruleResolvers';
import type { BaseLocationRule } from '~/server/transformers/banner/transformer/assignments/transformLocationRule';

export function resolveChannelLocation(
  channel: BannerAssignment,
  options: AssignmentResolverOptions,
) {
  return resolveLocationRule(channel.locationRule, options);
}

export function resolveLocationRule(
  rule: BaseLocationRule,
  options: AssignmentResolverOptions,
): boolean | undefined {
  const resolver = locationRuleResolvers[rule.typeHandle];
  if (resolver) {
    return resolver(rule, options);
  }
}
