import { resolveConditionRule } from './resolver';
import type { AssignmentResolverOptions } from '../resolveAssignment';
import type { LogicGroupConditionRule } from '~/server/transformers/banner/transformer/assignments/conditionRules/logicGroup';
import type { LoginStatusConditionRule } from '~/server/transformers/banner/transformer/assignments/conditionRules/loginStatus';
import { ConditionRuleType, LogicOperator } from '../../types';

export const conditionRuleResolvers: Record<
  ConditionRuleType,
  (rule: any, options?: AssignmentResolverOptions) => boolean
> = {
  [ConditionRuleType.LOGIC_GROUP]: resolveLogicGroup,
  [ConditionRuleType.LOGIN_STATUS]: resolveLoginStatus,
};

function resolveLogicGroup(
  rule: LogicGroupConditionRule,
  options: AssignmentResolverOptions,
): boolean {
  const { operator, rules } = rule;
  if (operator === LogicOperator.And) {
    return rules.every((cur) => resolveConditionRule(cur, options));
  } else {
    return rules.some((cur) => resolveConditionRule(cur, options));
  }
}

function resolveLoginStatus(
  rule: LoginStatusConditionRule,
  options: AssignmentResolverOptions,
): boolean {
  return (
    (rule.login && options.isLoggedIn) || (!rule.login && !options.isLoggedIn)
  );
}
